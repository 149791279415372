var mensagemCookies = document.querySelector('#message-cookies')
var btnCookies     = document.querySelector('#btnCookies');
var termos           = localStorage.getItem('termos.coockies');
var hj               = date();

//==========================================================
// Verificando se os dados já foram aceitos
//-========================================================
if(termos) {
	// Convertendo os dados para um obj JS
	termos = JSON.parse(termos);

  // Caso a data gegistrada for diferente da data de hj
  // remover a classe que esconde o #mensagem-cookies
  if(termos.data !== hj) {
  	mensagemCookies.classList.remove('d-none')
  }
}else {
	// caso não exista nenhum registro dos termos, exibi a msg
	mensagemCookies.classList.remove('d-none')
}

//=========================================================
// Botão "aceitar"
//=========================================================
btnCookies.addEventListener('click', function() {
	var data = {
  	// registrando o dia de HJ
  	data: date(),
    type: 'termos de cookies',
    aceito: true
  };
  // Convertendo o Obj data para string
  var stringData = JSON.stringify(data)

  // Regristrando strinData no LocalStorage
	localStorage.setItem('termos.coockies', stringData);

 	// Ocultando a mensagem após aceitar
  mensagemCookies.classList.add('d-none')
})

//=========================================================
// Utilitário para formatar a data
//=========================================================
function date() {
	var d= new Date();
	var dia = d.getDate();
	var mes = d.getMonth() + 1;
	var ano = d.getFullYear();
	var data = dia+'/'+(mes < 10 ? '0'+mes : mes)+'/'+ano;

  	return data;
}
